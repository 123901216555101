<template>
    <div class="wrapper">
        <div class="notifications" v-if="newNotifications.length > 0">
            <div class="notification-block" v-for="item in newNotifications" :key="item.id">
                <div class="information">
                    <div class="date">
                        <h3>{{ new Date(item.dateAdded).toLocaleDateString('ru-RU',  { year: 'numeric', month: 'long', day: 'numeric'})}}</h3>
                    </div>
                    <div class="header">
                        <ico v-if="item.priority === 'IMPORTANT'" name="icoError" color="#EC3323" size="30px"></ico>
                        <ico v-if="item.priority === 'SUCCESS'" name="icoCheckCircle" color="#12AE0E" size="30px"></ico>
                        <h3 v-html="JSON.parse(item.header.replaceAll(/\\n/gm, '<br>'))"></h3>
                    </div>
                    <p v-html="JSON.parse(item.content.replaceAll(/\\n/gm, '<br>'))"></p>
                </div>
                <div class="actions">
                    <p class="action-readed" v-on:click="markNotificationAsReaded(profileId, item.id)">Пометить прочитанным</p>
                    <router-link to="/profile/subscription"><button v-if="item.buttonIncluded" class="action-subscription btn btn-middle"> Продлить подписку</button></router-link>
                </div>
            </div>
        </div>
        <div v-else>
            <p>Новых уведомлений нет</p>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
    import { markNotificationChecked, markNotificationReaded } from '../../../application/requests/notificationsRequests';
    import Vue from 'vue';

    export default {
        name: "NewNotifications",
        computed: {
            ...mapGetters('notifications', [ 'newNotifications', 'displayedNotifications' ]),
            ...mapGetters('profile', ['profileId']),
        },
        methods: {
            ...mapActions('notifications', ['getAllNotifications', 'getNewNotifications']),
            async markNotificationAsReaded(profileId, notificationId){
                await markNotificationChecked(profileId, [notificationId])
                await markNotificationReaded(profileId, notificationId)
                await this.getAllNotifications(profileId)
                await this.getNewNotifications(profileId)
            }
        },
        mounted(){
            if(this.displayedNotifications.length > 0){
                this.displayedNotifications.forEach((item) => {
                    markNotificationChecked(this.profileId, [item.id])
                })
            }
            Vue.notify({
                group: 'templateNotification',
                clean: true,
            }) 
        },
    }
        
</script>

<style lang="less" scoped>
    .wrapper{
        .notifications{
            .notification-block{
                width: 670px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-bottom: 1px solid #EEEEEE;
                padding: 24px 0;

                .information{
                    font-size: 16px;

                    .header{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 0;
                    }
                }

                .actions{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 25px;
                    cursor: pointer;

                    .action-readed{
                        color: #7D919B;
                        user-select: none;
                    }  
                }
            }
        }
    }
</style>
